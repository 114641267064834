
import { Component, Vue, Watch } from 'vue-property-decorator';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { namespace } from 'vuex-class';
import { User } from '@/models/users/user.class';
import { FetchAllParams } from '@/models/shared/fetch-all-params.interface';
import Language from '@/models/languages/language.class';
import { translateApi } from '@/utils/translate-api';
import { TranslatedProperty } from '@/models/shared/translated-property.class';
import { UserUpdate } from '@/models/users/user-update.interface';
import { cloneDeep } from 'lodash';
import { Customer } from '@/models/customers/customer.class';

const authModule = namespace('auth');
const languagesModule = namespace('languages');
const usersModule = namespace('users');

@Component({
  components: { ValidationObserver, ValidationProvider },
})
export default class PersonalData extends Vue {
  translatedLanguages: TranslatedProperty[] = [];
  options: {}[] = [{ text: '', value: '' }];
  userLanguage!: string;
  showLoading = false;
  currentUser: User = new User();
  successMessageSeconds = 0;

  @authModule.Getter('authenticatedUser')
  user!: User;

  @authModule.Action('getMe')
  getAuthenticatedUser!: () => User;

  @languagesModule.Action('FETCH_UNPAGINATED')
  fetchLanguages!: (payload?: FetchAllParams) => Language[];

  @languagesModule.Getter('ALL')
  languages!: Language[];

  @usersModule.Action('UPDATE')
  updateUser!: (UserUpdate: UserUpdate) => User;

  @Watch('user', { immediate: true })
  onUserChanged(user) {
    this.currentUser = cloneDeep(user);
  }

  $refs!: Vue['$refs'] & {
    observer: InstanceType<typeof ValidationObserver>;
  };

  getTranslatedLanguages() {
    this.languages
      .map(language => language.name)
      .forEach(item => this.translatedLanguages.push(translateApi(item, this.$i18n.locale)));
    this.options = [
      { text: this.translatedLanguages[0], value: 'en' },
      { text: this.translatedLanguages[1], value: 'nl' },
      { text: this.translatedLanguages[2], value: 'fr' },
    ];
  }

  async onSubmit() {
    this.showLoading = true;
    const customer = this.currentUser.customer as Customer;
    this.currentUser.customer = customer._id;
    const updateUser: UserUpdate = { _id: this.currentUser._id as string, user: this.currentUser };
    const user = await this.updateUser(updateUser);
    if (user) {
      this.successMessageSeconds = 8;
      window.scrollTo(0, 0);
    }
    await this.getAuthenticatedUser();
    this.showLoading = false;
  }
  trimInput(value: string) {
    this.currentUser.phone = value.split(' ').join('');
  }

  async created() {
    await this.fetchLanguages();
    this.getTranslatedLanguages();
  }
}
