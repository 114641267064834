import { BaseModel } from '../base-model.class';
import { Customer } from '../customers/customer.class';
import { UserRole } from './user-role.enum';

export class User extends BaseModel {
  email = '';
  password = '';
  confirmPassword = '';
  role: UserRole = UserRole.USER;
  firstName?: string = '';
  lastName?: string = '';
  phone = '';
  active = false;
  allowMarketing = false;
  sendMail = false;
  createStudent = false;
  language = '';
  customer?: string | Customer;
  username?: string;
}
